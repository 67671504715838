import { NavLink } from "react-router-dom";
import { ReactComponent as FileMoney } from "./file-money.svg";
import { ReactComponent as Cog } from "./cog.svg";
import { ReactComponent as ArrowLeft } from "bootstrap-icons/icons/arrow-left-circle.svg";
import { backToClickSuper } from "../../utils/siteNavigation";
import { ParticipantContext } from "../../App";
import { useContext } from "react";

const Sidebar = () => {
  const { participant } = useContext(ParticipantContext);

  return (
    <aside className="sidebar d-print-none">
      <nav>
        <div className="sidebar__org">
          <span className="sidebar__org--name">
            {participant?.organisationName}
          </span>
          <span className="sidebar__org--abn">
            {participant?.businessIdentifier}
          </span>{" "}
          {participant?.branch && "/"}
          <span className="sidebar__org--branch">{participant?.branch}</span>
        </div>
        <ul className="sidebar__links">
          {/*<li>*/}
          {/*  <NavLink*/}
          {/*    className="d-flex align-items-center"*/}
          {/*    activeClassName="is-active"*/}
          {/*    exact*/}
          {/*    to="/"*/}
          {/*  >*/}
          {/*    <Home className="mr-4" />*/}
          {/*    <span>Dashboard</span>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          <li>
            <NavLink
              className="d-flex align-items-center"
              activeClassName="is-active"
              to="/stp"
            >
              <FileMoney className="mr-4" />
              <span>STP Reports</span>
            </NavLink>
          </li>
          {/*<li>*/}
          {/*  <NavLink*/}
          {/*    className="d-flex align-items-center"*/}
          {/*    activeClassName="is-active"*/}
          {/*    exact*/}
          {/*    to="/employees"*/}
          {/*  >*/}
          {/*    <Users className="mr-4" />*/}
          {/*    <span>Employees</span>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          <li>
            <NavLink
              className="d-flex align-items-center"
              activeClassName="is-active"
              exact
              to="/settings"
            >
              <Cog className="mr-4" />
              Settings
            </NavLink>
          </li>
        </ul>
        <div className="clicksuper-link">
          <button type="link" onClick={(e) => backToClickSuper()}>
            <ArrowLeft className="mr-4" /> Return to ClickSuper
          </button>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
