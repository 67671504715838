import { ReactComponent as Logo } from "../../assets/images/logo_wrkr-pay.svg";
import {backToClickSuper} from "../../utils/siteNavigation";

const Forbidden = () => {
  return (
    <div className="page__background">
      <div className="mb-8">
        <Logo />
      </div>
      <h1 className="title">403</h1>
      <h1>We are sorry...</h1>
      <p className="longform mb-2">
        The page you're trying to access has been denied, or your session has
        expired.
      </p>
      <p className="longform mb-8">
        Please return to ClickSuper and try again.
      </p>
      <button className="button button--primary" onClick={() => backToClickSuper()}>Return to ClickSuper</button>

      <form id="pay-submit" action="http://localhost:3000/token/" method="POST">
        <input type="hidden" name="token" value="eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNyc2Etc2hhMjU2IiwidHlwIjoiSldUIiwiaml0IjoiMjkyYWQxOWQtODAyYi00ZGQwLThhYWMtMzVlYTAwZGIxZWM4In0.eyJDaGFubmVsQ29kZSI6IkdHQUEyQSIsIkFCTiI6IjcyOTY4NTA0OTM0IiwiQnJhbmNoIjoiIiwiVXNlcm5hbWUiOiIzd3JzIiwiYXVkIjoiaHR0cHM6Ly9hdXRoLWRldi5ib25kLmNvbXBseXBhdGguY29tLmF1L3Rva2VuIiwiZXhwIjoxNjU1NDIyNDI5LCJpc3MiOiJDbGlja1N1cGVyIiwiaWF0IjoxNjU1NDIxNTI5LCJuYmYiOjE2NTU0MjE1Mjl9.KICoHg9WJGXzrFJMHIhvyeh2CohttmAR2KHH2x-panoH4F1ZLU_J-jr7VqZk9lQDqxrds3NsT0j_WddNMKmeMcCHw_w48uq0QvvycDiFpITWXdnd0DWxMSk8GLePoobQlTXPpvcCU_rA7lOwNES0-cV-jseAIwOCfUYdL5UfWE6DRom3bkamNpGKQtrI78Bwl_Gs4HUqXRmqlGPYU2cKSSQj6D5RDr9yDNbR9pe6jay2aBTyCieyFdSiXeUn0SYa-2lYHFmBDQbPafko7rkXs0XyAcg4w_2oXaifIHYBEHkBZI-PbC07-nUWW5uROY8TNkfopmJGxp7KPfR91p2o4OxCOX3ruJq97AXlw0vLF7MnYi4ltscEYUApSO_iQYCJHsjPdruLUK9MURRJ-2yLsAQf9Hstvp-AA27BIhGhZk-Y6icuWJp2jRPp8EzjrIO6NPkaRKWK_k4KwvyjXye7C5VZuGM_-W7TV-3P0UtCDp03x07A-St5XXfSAMJ1ZFQmQhzeWfiUT7X0CUvQLVaiCL1EmdVLzOehoAxE4d3PU_GhPuxmxVuZ2Thsr6OnY5ByWoTDCGENXbYttO3svcW99o3iFiEQhEG87Bk3LrSjp1TwQOyQ23H2QaEHq_ysXaBJtfPnR3FjAmisqQukBSuTNoT-yf0Wlvna8JBOErhxTd0" />
        <input id="submit" type="submit" value="Submit request" 
        
        onClick={(e) => {
          // e.preventDefault();
          window.location.href = "/provide/token"
        }}/>
      </form>
    </div>
  );
};

export default Forbidden;
